import * as React from 'react'
import clsx from 'clsx'
import { FlexContainer, FlexContainerProps } from '@layout/FlexContainer'
import styles from './ActionButtonsContainer.module.css'

export type ActionButtonsContainerProps = {
  justifyContentCenterOnMd?: boolean
  fullWidthButtonsOnSm?: boolean
  noTopGap?: boolean
  verticalOnSm?: boolean
} & FlexContainerProps

export const ActionButtonsContainer: React.FC<ActionButtonsContainerProps> = ({
  justifyContentCenterOnMd,
  fullWidthButtonsOnSm,
  noTopGap,
  verticalOnSm,
  className,
  ...props
}) => (
  <FlexContainer
    fixedDirection={!verticalOnSm}
    className={clsx(styles.actionButtonsContainer, className, {
      [styles.topGap]: !noTopGap,
      [styles.justifyContentCenterOnMd]: justifyContentCenterOnMd,
      [styles.fullWidthButtonsOnSm]: fullWidthButtonsOnSm
    })}
    {...props}
  />
)
